@import url('https://fonts.googleapis.com/css2?family=Acme&family=Shantell+Sans:ital,wght@0,300;1,300&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    html {
        font-family: acme, sans-serif;
    }
}